import { useCompletion } from '@ai-sdk/react'
import {
  ArrowLeftIcon,
  ArrowRotateRightLeftIcon,
  AutoCorrectIcon,
  ChevronDownIcon,
  CircleMinusIcon,
  CirclePlusIcon,
  CircleXFilledIcon,
  CrossLargeIcon,
  EmojiSmileIcon,
  MagicIcon,
  MagicWand2Icon,
  PencilWaveIcon,
  SquareBehindSquare2Icon,
  SuitcaseWorkIcon,
  TranslateIcon,
} from '@fingertip/icons'
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { Button, ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils'

import { Spinner } from '../shared/spinner'
import { Copy } from '../ui/copy'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { AIPrompt, AIPromptOptions, AIType } from './ai.types'
import { FORMAT_PLAINTEXT } from './ai.utils'

// Types
type AIScreenState =
  | { type: 'EMPTY' }
  | { type: 'INPUT'; input: string }
  | { type: 'GENERATING'; prompt: string }
  | { type: 'GENERATED'; prompt: string }
  | { type: 'CUSTOM_PROMPT'; input: string }
  | { type: 'EDITING' }

interface Props {
  content: string | undefined
  setContent: (content: string) => void
  setIsCompleting?: (isCompleting: boolean) => void
  format?: string
  suggestions?: string[][]
  onComplete?: () => void
  setOpen: (open: boolean) => void
}

interface TextInputProps {
  value: string
  onChange: (value: string) => void
  placeholder: string
  minRows?: number
  clearable?: boolean
  onClear?: () => void
  clearClassName?: string
}

type ListItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  // Your custom props
  children: React.ReactNode
  onClick?: () => void
}

const ListItem = forwardRef<HTMLButtonElement, ListItemProps>(
  ({ children, onClick, className = '', ...props }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        'relative group/item text-left cursor-pointer py-1 w-full',
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <div className="absolute inset-0 -mx-2 bg-gray-150 opacity-0 group-hover/item:opacity-100 dark:bg-gray-750" />
      <div className="relative flex items-center w-full">{children}</div>
    </button>
  ),
)

const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  (
    {
      value,
      onChange,
      placeholder,
      minRows = 2,
      clearable,
      onClear,
      clearClassName,
    },
    ref,
  ) => (
    <div className="relative w-full">
      <TextareaAutosize
        ref={ref}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="flex h-[52px] w-full rounded-2xl border-[1.5px] border-input bg-card px-4 py-[14px] font-sans text-base font-normal leading-snug text-foreground ring-offset-background transition-colors placeholder:text-placeholder-foreground hover:border-input-hover focus:border-ring focus:outline-hidden disabled:cursor-not-allowed disabled:opacity-50"
        minRows={minRows}
      />

      {clearable && !!value && (
        <div className="absolute right-0 top-0">
          <button
            tabIndex={-1}
            className={cn(
              'flex h-[52px] w-10 items-center cursor-pointer justify-center p-0! text-muted-foreground',
              clearClassName,
            )}
            type="button"
            onClick={() => onClear?.()}
            aria-label="clear input"
          >
            <CircleXFilledIcon className="size-4 text-muted-foreground/50" />
          </button>
        </div>
      )}
    </div>
  ),
)

const GeneratedContent = ({
  text,
  isCompleting,
}: {
  text: string
  isCompleting: boolean
}) => (
  <div className="relative max-h-[250px] min-h-[100px] overflow-y-auto rounded-lg p-3">
    <div
      className={cn('absolute inset-0', {
        'animate-pulse bg-muted': isCompleting,
        'bg-muted/50': !isCompleting,
      })}
    />
    <div
      className="relative prose prose-sm text-foreground prose-headings:text-foreground prose-p:text-foreground prose-a:text-foreground prose-a:underline prose-strong:text-foreground prose-em:text-foreground"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  </div>
)

const GeneratingScreen = ({
  prompt,
  completion,
  stop,
  isCompleting,
}: {
  prompt: string
  completion: string
  stop: () => void
  isCompleting: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-2">
      <div className="flex max-w-[calc(100%-36px)] items-center space-x-1">
        <div className="p-1">
          <Spinner size={20} />
        </div>
        <span className="truncate">{prompt}</span>
      </div>

      <GeneratedContent text={completion} isCompleting={isCompleting} />

      <div className="flex justify-end">
        <Button variant="secondary" size="sm" onClick={stop}>
          {t('stop_generation')}
        </Button>
      </div>
    </div>
  )
}

const GeneratedScreen = ({
  prompt,
  completion,
  isCompleting,
  handleBack,
  handleTryAgain,
  handleGenerate,
  handleAccept,
  hasContent,
  input,
  setInput,
}: {
  prompt: string
  completion: string
  isCompleting: boolean
  handleBack: () => void
  handleTryAgain: () => void
  handleGenerate: () => void
  handleAccept: () => void
  hasContent: boolean
  input: string
  setInput: (input: string) => void
}) => {
  const { t } = useTranslation()

  const [isThisBut, setIsThisBut] = useState(false)

  return (
    <div className="space-y-2">
      <div className="flex max-w-[calc(100%-36px)] items-center space-x-1">
        <button
          onClick={handleBack}
          className="rounded-sm p-1 transition-colors hover:bg-muted cursor-pointer"
        >
          <ArrowLeftIcon className="size-5" />
        </button>
        <span className="truncate">{prompt}</span>
      </div>

      <GeneratedContent text={completion} isCompleting={isCompleting} />

      {isThisBut ? (
        <div className="flex items-center justify-between w-full gap-1">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setInput('')
              setIsThisBut(false)
            }}
            className="px-2!"
          >
            <ArrowLeftIcon className="size-4" />
          </Button>

          <TextareaAutosize
            value={input || ''}
            onChange={(e) => setInput(e.target.value)}
            placeholder="This but..."
            autoFocus
            className="flex w-full border-none bg-transparent pl-1 font-sans text-base font-normal leading-snug text-foreground ring-offset-background placeholder:text-placeholder-foreground focus:outline-none"
          />

          <Button
            size="sm"
            onClick={() => {
              handleGenerate()
            }}
            disabled={!input || input.length < 1}
          >
            Generate
          </Button>
        </div>
      ) : (
        <div className="flex justify-between gap-1">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setIsThisBut(true)}
          >
            This but...
          </Button>

          <div className="flex gap-1">
            <Copy content={completion} copyMode="HTML">
              <ButtonDiv size="sm" variant="ghost" className="px-2!">
                <SquareBehindSquare2Icon className="size-4" />
              </ButtonDiv>
            </Copy>

            <Button size="sm" variant="secondary" onClick={handleTryAgain}>
              {t('try_again')}
            </Button>

            <Button size="sm" onClick={handleAccept}>
              {hasContent ? t('replace') : t('insert')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const EditingScreen = ({
  handleEditAction,
  handleCustomPrompt,
}: {
  handleEditAction: (
    action: AIType,
    prompt: string,
    options?: AIPromptOptions,
  ) => void
  handleCustomPrompt: () => void
}) => {
  const { t } = useTranslation()

  const languages = [
    t('en'),
    t('ko'),
    t('zh'),
    t('ja'),
    t('es'),
    t('ru'),
    t('fr'),
    t('portugese'),
    t('de'),
    t('it'),
    t('dutch'),
  ]

  return (
    <div className="flex flex-col gap-2">
      <div className="h6 flex items-center">
        <MagicIcon className="size-4 mr-2" />
        {t('edit_with_ai')}
      </div>

      <div className="flex flex-col">
        <ListItem onClick={handleCustomPrompt}>
          <PencilWaveIcon className="size-4 mr-2" />
          {t('custom_prompt')}
        </ListItem>

        <ListItem onClick={() => handleEditAction('rewrite', 'Rewrite')}>
          <ArrowRotateRightLeftIcon className="size-4 mr-2" />
          {t('rewrite')}
        </ListItem>

        <ListItem
          onClick={() =>
            handleEditAction('continueWriting', 'Continue writing')
          }
        >
          <CirclePlusIcon className="size-4 mr-2" />
          {t('continue_writing')}
        </ListItem>

        <ListItem onClick={() => handleEditAction('shorten', 'Shorten')}>
          <CircleMinusIcon className="size-4 mr-2" />
          {t('shorten')}
        </ListItem>

        <ListItem
          onClick={() => handleEditAction('fixSpelling', 'Fix spelling')}
        >
          <AutoCorrectIcon className="size-4 mr-2" />
          {t('fix_spelling')}
        </ListItem>

        <ListItem onClick={() => handleEditAction('improve', 'Improve')}>
          <MagicWand2Icon className="size-4 mr-2" /> {t('improve')}
        </ListItem>

        <ListItem
          onClick={() =>
            handleEditAction('changeVoice', 'More fun', {
              tone: 'fun',
            })
          }
        >
          <EmojiSmileIcon className="size-4 mr-2" /> {t('more_fun')}
        </ListItem>

        <ListItem
          onClick={() =>
            handleEditAction('changeVoice', 'More formal', {
              tone: 'formal',
            })
          }
        >
          <SuitcaseWorkIcon className="size-4 mr-2" /> {t('more_formal')}
        </ListItem>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ListItem>
              <TranslateIcon className="size-4 mr-2" />
              {t('translate')}
              <ChevronDownIcon className="size-3 ml-1" />
            </ListItem>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            {languages.map((language, index) => (
              <DropdownMenuItem
                key={index}
                onClick={() =>
                  handleEditAction('translate', `Translate to ${language}`, {
                    translationLanguage: language,
                  })
                }
              >
                {language}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}

const CustomPromptScreen = ({
  input,
  handleInputChange,
  handleBack,
  handleClear,
  handleGenerate,
  inputRef,
}: {
  input: string
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleBack: () => void
  handleClear: () => void
  handleGenerate: () => void
  inputRef: React.RefObject<HTMLTextAreaElement | null>
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <button
          onClick={handleBack}
          className="rounded-sm p-1 transition-colors hover:bg-muted cursor-pointer"
        >
          <ArrowLeftIcon className="size-5" />
        </button>

        <div className="font-medium">{t('custom_prompt_0')}</div>
      </div>

      <TextInput
        value={input}
        onChange={(newInput) => {
          handleInputChange({ target: { value: newInput } } as any)
        }}
        placeholder={t('describe_your_writin')}
        ref={inputRef}
        clearable
        onClear={handleClear}
      />

      <div className="flex justify-end">
        <Button size="sm" onClick={handleGenerate} disabled={input.length < 1}>
          {t('generate')}
        </Button>
      </div>
    </div>
  )
}

export const AskAI = ({
  content = '',
  setContent,
  setIsCompleting: setIsCompletingProp,
  format = FORMAT_PLAINTEXT,
  suggestions = [],
  onComplete,
  setOpen,
}: Props) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [state, setState] = useState<AIScreenState>(
    content.length > 0 ? { type: 'EDITING' } : { type: 'EMPTY' },
  )
  const [tryAgain, setTryAgain] = useState<AIPrompt | null>(null)

  const {
    setInput,
    completion,
    complete,
    isLoading: isCompleting,
    input,
    handleInputChange,
    stop,
  } = useCompletion({
    api: '/api/completion',
    initialCompletion: content,
    credentials: 'omit',
  })

  useEffect(() => {
    if (content.length === 0) {
      setState({ type: 'EMPTY' })
    }
  }, [content.length])

  const getCompletion = useCallback(
    async (type: AIType, prompt: string, options?: AIPromptOptions) => {
      setState({ type: 'GENERATING', prompt })
      let body: AIPrompt = {
        content,
        type,
        options,
        format,
        responseFormatType: 'text',
      }
      if (type === 'tryAgain') {
        body = {
          ...tryAgain,
          format,
          responseFormatType: 'text',
        }
      } else {
        setTryAgain(body)
      }
      setInput('')
      await complete('', { body })
      setState({ type: 'GENERATED', prompt })
    },
    [complete, content, format, setInput, tryAgain],
  )

  useEffect(() => {
    setIsCompletingProp?.(isCompleting)
  }, [isCompleting, setIsCompletingProp])

  const handleAccept = useCallback(() => {
    setContent(completion)
    setState({ type: 'EDITING' })
    onComplete?.()
  }, [completion, onComplete, setContent])

  const handlePromptClick = useCallback(
    (promptTitle: string) => {
      setInput(promptTitle)
      setState({ type: 'INPUT', input: promptTitle })
      inputRef.current?.focus()
    },
    [setInput],
  )

  const handleClear = useCallback(() => {
    setInput('')
  }, [setInput])

  const handleGenerate = useCallback(() => {
    getCompletion('promptTitle', input, { title: input })
  }, [getCompletion, input])

  const handleEditAction = useCallback(
    (action: AIType, prompt: string, options?: AIPromptOptions) => {
      getCompletion(action, prompt, options)
    },
    [getCompletion],
  )

  const handleCustomPrompt = useCallback(() => {
    setState({ type: 'CUSTOM_PROMPT', input: '' })
  }, [])

  const handleBack = useCallback(() => {
    setState(content ? { type: 'EDITING' } : { type: 'EMPTY' })
  }, [content])

  const showSuggestions = input.length === 0

  return (
    <div className="relative">
      <div className="absolute right-0 top-0">
        <Button size="xs" variant="ghost" onClick={() => setOpen(false)}>
          <CrossLargeIcon className="size-4" />
          <div className="sr-only">{t('close')}</div>
        </Button>
      </div>

      <div className="space-y-2">
        {['EMPTY', 'INPUT'].includes(state.type) && (
          <>
            <div className="h6 flex items-center">
              <MagicIcon className="size-4 mr-2" />
              {t('write_with_ai')}
            </div>

            <TextInput
              value={input}
              onChange={(newInput) => {
                handleInputChange({ target: { value: newInput } } as any)
              }}
              placeholder={t('describe_your_writin')}
              ref={inputRef}
              minRows={1}
              onClear={handleClear}
              clearable
            />

            {showSuggestions ? (
              <div className="flex flex-col">
                {suggestions.map(([title, prompt], index) => (
                  <ListItem
                    key={index}
                    onClick={() => handlePromptClick(prompt)}
                  >
                    {title}
                  </ListItem>
                ))}
              </div>
            ) : (
              <div className="flex justify-end">
                <Button
                  size="sm"
                  onClick={handleGenerate}
                  disabled={input.length < 1}
                >
                  {t('generate')}
                </Button>
              </div>
            )}
          </>
        )}

        {state.type === 'GENERATING' && (
          <GeneratingScreen
            prompt={state.prompt}
            completion={completion}
            stop={stop}
            isCompleting={isCompleting}
          />
        )}

        {state.type === 'GENERATED' && (
          <GeneratedScreen
            prompt={state.type === 'GENERATED' ? state.prompt : 'This but...'}
            completion={completion}
            handleBack={handleBack}
            handleTryAgain={() => getCompletion('tryAgain', state.prompt)}
            handleGenerate={handleGenerate}
            handleAccept={handleAccept}
            hasContent={!!content}
            isCompleting={isCompleting}
            input={input}
            setInput={setInput}
          />
        )}

        {state.type === 'CUSTOM_PROMPT' && (
          <CustomPromptScreen
            input={input}
            handleInputChange={handleInputChange}
            handleBack={handleBack}
            handleClear={handleClear}
            handleGenerate={handleGenerate}
            inputRef={inputRef}
          />
        )}

        {state.type === 'EDITING' && (
          <EditingScreen
            handleEditAction={handleEditAction}
            handleCustomPrompt={handleCustomPrompt}
          />
        )}
      </div>
    </div>
  )
}
